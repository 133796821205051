import React from "react"
import Layout from "../components/flayout"
import SubscriptionPrefs from "../components/subscriptionPrefs"
import { Router } from "@reach/router"
import ContentPage from "../components/contentPage"
import DefaultLoader from "../shared/loader";

const Page = ({location}) => (
    <Layout darkHeader={true} showAnnouncement={false} location={location} seoFields={seoFields}>
        <ContentPage title={'Your MProfit Subscription Preferences'}>
        <Router>
            <SubscriptionPrefs path={'/subscription-prefs/:email/:jobId/:expirationDt/:signature'} location={location}/>
            <DefaultLoader path={'/subscription-prefs'} isLoad={true} isFull={true}/>
        </Router>
        </ContentPage>
    </Layout>
)

const seoFields = {
    title: "Subscription Preferences" 
  }

export default Page
