import React from "react"
import { createUrlQueryParams } from "../../common/gFunctions";

import { getSubscriptionPrefs, updateSubscriptionPrefs } from "../../services/customerInfo";

import ProceedButton from "../proceedButton";

import styles from "./index.module.css";

import DefaultLoader from "../../shared/loader";

export class SubscriptionPrefs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionAuth: null,
            subscriptionPrefs: null,
            isSuccess: false,
            isError: false,
            isLoading: false
        }
    }
    componentDidMount() {
        // console.log('SubscriptionPrefsLocation', this.props.location);
        var subscriptionPrefs = null;
        var originalSubscriptionPrefs = null;
        var subscriptionAuth = null;
        try {
            var subscriptionAuthSplit = this.props.location.pathname.split('/');
            subscriptionAuth = {
                encodedEmailAddr: decodeURIComponent(subscriptionAuthSplit[2]),
                encodedEmailJobId: subscriptionAuthSplit[3],
                expirationDt: subscriptionAuthSplit[4],
                signature: subscriptionAuthSplit[5]
            }

            getSubscriptionPrefs(subscriptionAuth)
            .then(({error,response}) => {
                console.log(error,response,'getSubscriptionPrefs')
                if (error.status < 400) {
                    subscriptionPrefs = {...response};
                    originalSubscriptionPrefs = {...response};
                    this.setState({subscriptionAuth, subscriptionPrefs, originalSubscriptionPrefs});
                } else {
                    this.setState({isInvalid: true});
                }
            })
        } catch {
        }
    }

    saveSubscriptionPrefs() {
        this.setState({isLoading: true});
        updateSubscriptionPrefs(this.state.subscriptionAuth, this.state.subscriptionPrefs)
        .then(({error,response}) => {
            console.log(error,response,'getSubscriptionPrefs')
            if (error.status < 400) {
                this.setState({isError: false, isSuccess: true, isLoading: false, isUnsubscribed: !this.state.subscriptionPrefs.IsSubscribedMarketingEmails})
            } else {
                this.setState({isLoading: false, isInvalid: error.status == 400, isUnsubscribed: false})
            }
        })
    }

    onChangeSubscriptionPrefs = (e) => {
        const value = e.target.checked;
        const section = this.state.subscriptionPrefs;
        section[e.target.name] = value;
        this.setState({[section]: section});
    }

    render() {
        const {subscriptionAuth, subscriptionPrefs, originalSubscriptionPrefs, isLoading, isSuccess, isInvalid, isUnsubscribed} = this.state;
        console.log(subscriptionPrefs, originalSubscriptionPrefs, 'subscriptionPrefs');
        return (
            <>
                {
                    (isSuccess || isInvalid) ?
                    (
                        <div>
                            <span className={styles.statusMsg}>{isSuccess ? (isUnsubscribed ? 'You have been unsubscribed successfully.' : 'Your subscription preferences have been saved successfully.') : 'Please note that this unsubscribe link has expired'}</span>
                        </div>
                    )
                    :
                    subscriptionAuth && subscriptionPrefs ?
                    <>
                    <div className={[styles.emailContainer].join(' ')}>
                        <span className={[styles.emailLabel].join(' ')}>Email</span>
                        <span className={[styles.emailValue].join(' ')}>{decodeURIComponent(subscriptionAuth.encodedEmailAddr)}</span>
                    </div>
                    <div className={["custom-checkbox", styles.subscriptionPrefsCheckboxContainer].join(" ")}>    
                        <input className={["custom-input",styles.subscriptionPrefsCheckbox].join(" ")} id="subscription-prefs-marketing" name="IsSubscribedMarketingEmails" type="checkbox" checked={subscriptionPrefs.IsSubscribedMarketingEmails} onChange={(event)=>this.onChangeSubscriptionPrefs(event)} />
                        {/* <label className={[styles.subscriptionPrefsCheckboxLabel].join(" ")} htmlFor="subscription-prefs-marketing"></label> */}
                        <label className={styles.subscriptionPrefsCheckboxText} htmlFor="subscription-prefs-marketing">Subscribed to Newsletter and Marketing Emails</label>
                    </div>
                    <div className={[styles.proceedButtonContainer].join(' ')}>
                        <ProceedButton buttonText={'Save'} onClick={() => {this.saveSubscriptionPrefs()}} borderRadius={3} disabled={!originalSubscriptionPrefs || !subscriptionPrefs || (originalSubscriptionPrefs.IsSubscribedMarketingEmails == subscriptionPrefs.IsSubscribedMarketingEmails)} loading={isLoading}/>
                    </div>
                    </>
                    :
                    <>
                    <DefaultLoader isLoad={true} isFull={true}/>
                    </>
                }
            </>
        )
    }
}

export default SubscriptionPrefs
